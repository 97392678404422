<template>
  <v-app>
    <v-navigation-drawer v-model="drawer" :rail="rail" permanent v-if="isLoggedIn"
      @click="onToggleMenu('v-navigation-drawer')">
      <BaseDialog :showDialog="appDialogShow" :title="appDialogTitle" :message="appDialogMessage"
        :close="appDialogClose" :closingRoute="appDialogClosingRoute" @show="show" v-show="$route.path === '/login' ? false : true">
      </BaseDialog>
      <BaseNav v-show="$route.path === '/login' ? false : true"></BaseNav>
    </v-navigation-drawer>

    <v-app-bar color="primary">
      <v-app-bar-nav-icon v-if="isLoggedIn" @click="onToggleMenu('v-app-bar-nav-icon')"></v-app-bar-nav-icon>
      <v-app-bar-title>
        <BaseNavBreadcrumbs></BaseNavBreadcrumbs>
      </v-app-bar-title>

      <v-spacer></v-spacer>

      <BaseCsvExportDialog :data="applicationData"></BaseCsvExportDialog>

      <v-btn icon @click="onViewApplicationDataClick">
        <v-icon>fas fa-code</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <v-row>
          <v-col>
            <router-view @error="error" @dialog="dialog" :key="$route.fullPath"
              @onApplicationDataUpdate="setApplicationData"></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { ref, watch, onMounted } from 'vue'
import { useDisplay } from 'vuetify'
import { useRoute } from 'vue-router';
import { useAuth } from '@/composables/useAuth'
import BaseDialog from './components/BaseDialog.vue'  // Adjust the import path as needed
import BaseNav from './components/BaseNav.vue'
import { useUserPreferences } from '@/composables/userPreferences'

export default {
  components: {
    BaseDialog,
    BaseNav
  },
  setup() {
    const { preferences, updatePreference } = useUserPreferences()
    const appError = ref(new Error())
    const appDialogShow = ref(false)
    const appDialogTitle = ref('Test')
    const appDialogMessage = ref('Are you sure you want to do this thing?')
    const appDialogClose = ref(false)
    const appDialogClosingRoute = ref(false)

    const auth = useAuth()
    const isLoggedIn = ref(auth.isAuthenticated.value);
    const route = useRoute();
    const pageTile = ref('');

    const applicationData = ref(null);
    const applicationDataUrls = ref([]);

    const { mobile } = useDisplay()
    const isMobile = ref(mobile.value)

    const rail = ref(null)
    const drawer = ref(null)

    watch(() => route.path, () => {
      setAuthState();
    })

    const error = (e) => {
      appError.value = e
      let message;
      if (e.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        message = e.response.data
      } else if (e.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        message = e.request
      } else {
        // Something happened in setting up the request that triggered an Error
        message = ''
      }
      dialog('Error Details', message, undefined)
    }

    const dialog = (title, message, close, closingRoute) => {
      console.log('App.vue', title, message, close, closingRoute)

      if (appDialogShow.value) {
        // If the dialog is already open, close it
        appDialogShow.value = false;
      }

      setTimeout(() => {{
        appDialogShow.value = true;
        appDialogTitle.value = title
        appDialogMessage.value = message
        appDialogClose.value = close
        appDialogClosingRoute.value = closingRoute
      }}, 1)
    }

    const show = (value) => {
      appDialogShow.value = value
    }

    const setAuthState = () => {
      isLoggedIn.value = auth.isAuthenticated.value;
    }

    const setApplicationData = (payload) => {
      let data = payload.applicationData.data ? payload.applicationData.data : payload.applicationData;
      if (Array.isArray(data)) {
        // CSV export requires an array, do nothing
      } else {
        // Turn this in to an array
        data = [data];
      }
      applicationData.value = data;
      applicationDataUrls.value = payload.pageUrls ? payload.pageUrls : [];
    }

    const onViewApplicationDataClick = () => {
      dialog('Application Data', { urls: applicationDataUrls.value, data: applicationData.value }, () => { })
    }

    const onToggleMenu = (source) => {
      if (isMobile.value) {
        // We are on a mobile device, toggle the drawer state
        drawer.value = !drawer.value;
      } else {
        // We are on a desktop device
        if (source === 'v-app-bar-nav-icon') {
          // Only close the nav drawer when the burger icon is clicked
          rail.value = !rail.value;
          updatePreference('navigationRail', rail.value);
        }
      }
    }

    onMounted(() => {
      if (isMobile.value) {
        drawer.value = false;
        rail.value = false;
      } else {
        drawer.value = preferences.value.navigationDrawer;
        rail.value = preferences.value.navigationRail;
      }
    })

    return {
      drawer,
      rail,
      pageTile,
      isLoggedIn,
      appError,
      appDialogShow,
      appDialogTitle,
      appDialogMessage,
      appDialogClose,
      appDialogClosingRoute,
      applicationData,
      error,
      dialog,
      show,
      setApplicationData,
      onViewApplicationDataClick,
      onToggleMenu
    }
  }
}
</script>

<style>
.v-application {
  font-family: "Figtree", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.text-body-1,
.text-h1,
.text-h2,
.text-h3,
.text-h4,
.text-h5 {
  font-family: "Figtree", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
</style>